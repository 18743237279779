export default [
  {
    header: 'MailX',
    icon: 'MailIcon',
    children: [
      {
        title: 'MailX Plans',
        route: 'mailx-plans',
        icon: 'ShoppingCartIcon',
      },
      {
        title: 'MailX Orders',
        route: 'mailx-orders',
        icon: 'CreditCardIcon',
      },
    ],
  },
]
