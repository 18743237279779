export default [
  {
    header: 'ShadowX',
    icon: 'CloudIcon',
    children: [
      {
        title: '订购',
        route: 'shadowx-plans',
        icon: 'ShoppingCartIcon',
      },
      {
        title: '订单',
        route: 'shadowx-orders',
        icon: 'CreditCardIcon',
      },
    ],
  },
]
