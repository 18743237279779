export default [
  {
    header: '账单/充值',
    icon: 'CreditCardIcon',
    children: [
      {
        title: '充值',
        route: 'pay',
        icon: 'ColumnsIcon',
      },
    ],
  },
]
