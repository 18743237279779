export default [
  {
    header: 'Dashboards',
    icon: 'HomeIcon',
    children: [
      {
        title: '用户中心',
        route: 'dashboard',
        icon: 'ActivityIcon',
      },
    ],
  },
]
